@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'vendor/all';
@import 'components/all';

.container {
    @apply max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8;
}

[v-cloak] {
    @apply hidden;
}
